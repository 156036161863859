<!--热力图-->
<template>
  <div class="map-wrapper">
    <div id="container" style="width: 100%;height: 100%; z-index: 1;"></div>
  </div>
</template>

<script>

  import { HEAT_MAP_ROAD_SIGNS } from "../../assets/images/map/roadSign"
  import ampUtil from '../../utils/ampUtil'
  import Panel from '../../components/common/Panel'

  let map
  // 热力图
  let heatmap

  export default {
    name: "HeatMap",
    components: { Panel },
    data(){
      return{
        mapCenter:[100.634697,37.929996]
      }
    },
    methods:{
      setHeatmapData (heatmapData) {
        heatmap.setDataSet({
          data: heatmapData,
          max: 10
        });
      },
      mapCreate() {

        map = ampUtil.createAnalyseMap('container', this.mapCenter,
                // 'amap://styles/b49663ed32df732c0cf580fce9d38749')
                'amap://styles/914383f63364635eeaf00f796caf77a0')

        // 限制地图显示范围
        map.setLimitBounds(map.getBounds())
        // 设置鼠标样式
        map.setDefaultCursor('default')
        // 只显示甘肃省地图
        ampUtil.hideMapOtherAreasBySearch('甘肃省',map)

        map.plugin(["AMap.Heatmap"], function () {
          //初始化heatmap对象
          heatmap = new AMap.Heatmap(map, {
            radius: 25, //给定半径
            opacity: [0, 0.8]
          });
          // heatmap.setDataSet({
          //   // {"lng":116.382196,"lat":39.941606,"count":14}
          //   data: heatmapData,
          //   max: 100
          // });
        });
      },
      createRoadSignMarker () { // 高速路指示牌
        let roadSignMarkers = []
        HEAT_MAP_ROAD_SIGNS.forEach(sign => {
          let position = [sign.lng, sign.lat]
          let iconOptions = {
            size: new AMap.Size(32, 24),
            imageSize: new AMap.Size(32, 24),
            image: sign.src  // Icon的图像
          }

          let marker = new AMap.Marker({
            position: position,
            icon: new AMap.Icon(iconOptions),
            zIndex: 999,
            offset: new AMap.Pixel(-24, -18),
            angle: 10
          });

          // map.add(marker)

          roadSignMarkers.push(marker);
          this.roadSignLayerGroup = ampUtil.createOverlayGroup(roadSignMarkers)
          this.roadSignLayerGroup.setMap(map)
          // this.roadSignLayerGroup.hide()
        })
      },
    },
    mounted () {
      this.$nextTick(() => {
        this.mapCreate()
        this.createRoadSignMarker() // 路标指示牌
      })
    }
  }
</script>

<style lang="less" scoped>

  @import url("https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css");

  .map-wrapper {
    float: right;
    width: 780px;
    height: 100%;
  }

  .amap-container /deep/ .amap-logo {
    right: 0 !important;
    left: auto !important;
    display: none !important;
  }

  .amap-container /deep/ .amap-copyright {
    right: 70px !important;
    left: auto !important;
    opacity:0 !important;
  }

</style>
