<!--事件类别分析-->
<template>
  <div>
    <panel>

      <div class="panel-title">
        事件类别分析
      </div>

      <!--汽车-->
      <div class="type-item type-item-car">
        <div>
          <img src="../../assets/images/analyse/car.png">
        </div>
        <div class="total-count animated" v-if="eventData !== null" :class="{flipInX: flushFlag}">
          {{eventData.vehicle.car}}
        </div>
        <div class="ring-count" v-if="eventData !== null" :class="{'ring-count-up': eventData.vehicleRingRatio.car >= 0, 'ring-count-down': eventData.vehicleRingRatio.car < 0}">
          <span class="triangle-arrow"></span>
          <span style="margin-left: 4px;">{{Math.abs(eventData.vehicleRingRatio.car)}}</span>
        </div>
      </div>

      <!--非机动车-->
      <div class="type-item type-item-bicycle">
        <div>
          <img src="../../assets/images/analyse/bicycle.png">
        </div>
        <div class="total-count animated" v-if="eventData !== null" :class="{flipInX: flushFlag}">
          {{eventData.vehicle.bicycle}}
        </div>
        <div class="ring-count" v-if="eventData !== null" :class="{'ring-count-up': eventData.vehicleRingRatio.bicycle >= 0, 'ring-count-down': eventData.vehicleRingRatio.bicycle < 0}">
          <span class="triangle-arrow"></span>
          <span style="margin-left: 4px;">{{Math.abs(eventData.vehicleRingRatio.bicycle)}}</span>
        </div>
      </div>

      <!--行人-->
      <div class="type-item type-item-person">
        <div>
          <img src="../../assets/images/analyse/person.png">
        </div>
        <div class="total-count animated" v-if="eventData !== null" :class="{flipInX: flushFlag}">
          {{eventData.vehicle.person}}
        </div>
        <div class="ring-count" v-if="eventData !== null" :class="{'ring-count-up': eventData.vehicleRingRatio.person >= 0, 'ring-count-down': eventData.vehicleRingRatio.person < 0}">
          <span class="triangle-arrow"></span>
          <span style="margin-left: 4px;">{{Math.abs(eventData.vehicleRingRatio.person)}}</span>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>

  import Panel from '../../components/common/Panel'

export default {
  name: "TypeAnalyse",
  components: { Panel },
  props: {
    eventData: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    // 监听value发生变化，动效生效
    eventData () {
      this.flushFlag = false
      setTimeout(() => {
        this.flushFlag = true
      }, 100)
    }
  },
  data(){
    return{
      flushFlag: true, // 数据刷新标识,用于控制刷新效果
    }
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>

  @import './analyse.less';

  .panel-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    padding-top: 16px;
    color: #0088FF;
  }

  .type-item {
    width: 100px;
    height: 100px;
    border-radius: 16px;
    position: relative;
    margin: 0 auto;

    .total-count {
      font-size: 24px;
      font-family: lcd;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 29px;
    }

    .ring-count {
      font-size: 18px;
      font-family: lcd;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .type-item-car {
    margin-top: 16px;
    background: rgba(0, 203, 255, 0.08);
    border: 1px solid rgba(0, 203, 255, 0.65);
  }
  .type-item-bicycle {
    margin-top: 8px;
    background: rgba(0, 255, 144, 0.08);
    border: 1px solid rgba(0, 255, 144, 0.65);
  }
  .type-item-person {
    margin-top: 8px;
    background: rgba(255, 230, 0, 0.08);
    border: 1px solid rgba(255, 230, 0, 0.65);
  }

</style>
