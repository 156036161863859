<!--所属单位排名-->
<template>
  <div>
    <panel>

      <div class="panel-title">
        所属单位排名
      </div>
      <div class="chart-wrapper">
<!--        <v-chart style="height: 154px; width: 340px;"  :options="polar"></v-chart>-->
        <div id="department-chart-container" style="height: 154px; width: 340px;"></div>
      </div>

    </panel>
  </div>
</template>

<script>

  import Panel from '../../components/common/Panel'
  import echarts from 'echarts/lib/echarts'
  import ECharts from 'vue-echarts'
  import 'echarts/index'
  import { getAllDepartment } from '../../api/department'

  let chart

  export default {
    name: "DepartmentRanking",
    components: {
      Panel,
      'v-chart': ECharts
    },
    data(){
      return{
        polar: {
          grid: {
            left: 110,
            top: 8,
            width: 170,
            height: 132,
          },
          xAxis: {
            type: 'value',
            show: false,
            position: 'top'
          },
          yAxis: {
            type: 'category',
            // data: ['宝天收费所', '宝天收费所', '宝天收费所', '宝天收费所', '宝天收费所'],
            data: [],
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.65)',
              fontSize: 14,
              fontWeight: 400,
              fontFamily: 'PingFang SC',
              formatter: function (value) {   // 防止名称过长超出边界
                  let res = value
                  if (res.length > 9) {
                      res = res.substring(0, 8) + '...'
                  }
                  return res
              }
            }
          },
          series: [{
            // data: [70, 80, 120, 150, 200],
            data: [],
            type: 'bar',
            barWidth: 8,
            label: {
              show: true,
              color: '#0088FF',
              fontSize: 14,
              fontWeight: 'bold',
              fontFamily: 'PingFang SC',
              position: 'right',
            },
            itemStyle:{
              color: {
                colorStops: [{
                  offset: 0,   //颜色的开始位置
                  color: '#00CBFF' // 0% 处的颜色
                },{
                  offset: 1,    //颜色的结束位置
                  color: '#0088FF' // 100% 处的颜色
                }]
              },
              barBorderRadius: [4] //设置圆角
            }
          }]
        }
      }
    },
    methods:{
      refreshData (eventData) {
          let departmentNames = Object.keys(eventData.department)
          let departmentCounts = Object.values(eventData.department)

          if (departmentNames.length < 5) { // 原数据不够5条,手动补齐五条数据
              getAllDepartment().then(res => {
                  for(let item of res.data.filter(item => item.parentId === '3000000000000000002')){
                      if (departmentNames.indexOf(item.name) === -1) {
                          departmentNames.unshift(item.name)
                          departmentCounts.unshift(0)
                      }
                      if (departmentNames.length === 5) {
                          break
                      }
                  }
                  this.polar.yAxis.data = departmentNames
                  this.polar.series[0].data = departmentCounts
                  chart.clear()
                  chart.setOption(this.polar)
              })
          } else { // 原数据够5条,直接展示
              this.polar.yAxis.data = departmentNames
              this.polar.series[0].data = departmentCounts
              chart.clear()
              chart.setOption(this.polar)
          }
      }
    },
    mounted () {
      this.$nextTick(() => {
        chart = echarts.init(document.getElementById('department-chart-container'))
      })
    }
  }
</script>

<style lang="less" scoped>

  .panel-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #0088FF;
    margin-left: 16px;
    padding-top: 16px;
  }


  .chart-wrapper {
    position: relative;
    width: 340px;
    height: 194px;
  }

</style>
