<!--所处位置事件分析-->
<template>
  <div>
    <panel>

      <div class="panel-title">
        所处位置事件分析
      </div>

      <div>
        <div class="location-item" @mouseover="handleEventOver(0)" @mouseleave="handleEventLeave(0)" style="margin-top: 24px;">
          <div class="location-item-name">收费站</div>
          <div class="location-item-number" v-if="eventData !== null">
            <div class="location-item-number-total">{{eventData.location.toll}}</div>
            <div class="location-item-number-ring" :class="{'ring-count-up': eventData.locationRingRatio.toll >= 0, 'ring-count-down': eventData.locationRingRatio.toll < 0}">
              <span class="triangle-arrow"></span>
              <span style="margin-left: 4px;">{{Math.abs(eventData.locationRingRatio.toll)}}</span>
            </div>
          </div>
        </div>

        <div class="location-item" @mouseover="handleEventOver(1)" @mouseleave="handleEventLeave(1)">
          <div class="location-item-name">隧道</div>
          <div class="location-item-number" v-if="eventData !== null">
            <div class="location-item-number-total">{{eventData.location.tunnel}}</div>
            <div class="location-item-number-ring" :class="{'ring-count-up': eventData.locationRingRatio.tunnel >= 0, 'ring-count-down': eventData.locationRingRatio.tunnel < 0}">
              <span class="triangle-arrow"></span>
              <span style="margin-left: 4px;">{{Math.abs(eventData.locationRingRatio.tunnel)}}</span>
            </div>
          </div>
        </div>

        <div class="location-item" @mouseover="handleEventOver(2)" @mouseleave="handleEventLeave(2)">
          <div class="location-item-name">外场</div>
          <div class="location-item-number" v-if="eventData !== null">
            <div class="location-item-number-total">{{eventData.location.outfield}}</div>
            <div class="location-item-number-ring" :class="{'ring-count-up': eventData.locationRingRatio.outfield >= 0, 'ring-count-down': eventData.locationRingRatio.outfield < 0}">
              <span class="triangle-arrow"></span>
              <span style="margin-left: 4px;">{{Math.abs(eventData.locationRingRatio.outfield)}}</span>
            </div>
          </div>
        </div>

        <div class="location-item" @mouseover="handleEventOver(3)" @mouseleave="handleEventLeave(3)">
          <div class="location-item-name">服务区</div>
          <div class="location-item-number" v-if="eventData !== null">
            <div class="location-item-number-total">{{eventData.location.serviceArea}}</div>
            <div class="location-item-number-ring" :class="{'ring-count-up': eventData.locationRingRatio.serviceArea >= 0, 'ring-count-down': eventData.locationRingRatio.serviceArea < 0}">
              <span class="triangle-arrow"></span>
              <span style="margin-left: 4px;">{{Math.abs(eventData.locationRingRatio.serviceArea)}}</span>
            </div>
          </div>
        </div>

        <div class="location-item" @mouseover="handleEventOver(4)" @mouseleave="handleEventLeave(4)">
          <div class="location-item-name">桥梁</div>
          <div class="location-item-number" v-if="eventData !== null">
            <div class="location-item-number-total">{{eventData.location.bridge}}</div>
            <div class="location-item-number-ring" :class="{'ring-count-up': eventData.locationRingRatio.bridge >= 0, 'ring-count-down': eventData.locationRingRatio.bridge < 0}">
              <span class="triangle-arrow"></span>
              <span style="margin-left: 4px;">{{Math.abs(eventData.locationRingRatio.bridge)}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="chart-wrapper">
<!--        <v-chart style="height: 190px; width: 540px; margin: 0 auto;"  :options="polar"></v-chart>-->
<!--        <div id="location-chart-container" style="height: 196px; width: 540px; margin: 0 auto;"></div>-->
        <div id="location-chart-container" style="height: 250px; width: 500px; margin: 0 auto;"></div>
      </div>



    </panel>
  </div>
</template>

<script>

  import Panel from '../../components/common/Panel'
  import ECharts from 'vue-echarts'
  import 'echarts/index'
  import echarts from 'echarts/lib/echarts'

  let chart

export default {
  name: "LocationAnalyse",
  components: {
    Panel,
    'v-chart': ECharts
  },
  data(){
    return{
      eventData: null,
      polar: {
        // tooltip: {
        //   trigger: 'item'
        // },
        series: [
          {
            name: '事件数量',
            type: 'pie',
            radius: ['35%', '70%'],
            avoidLabelOverlap: false,
            labelLine:{
              // length:30,//第一段线长
              length2:130 //第二段线长
            },
            label: {
              formatter: (params) => {
                return `{color${params.dataIndex}|${params.name}：${params.value}（${params.percent}%）}`
              },
              padding: [-15,-140,0,-140],
              color: 'red',
              fontSize: 14,
              fontWeight: 400,
              fontFamily: 'PingFang SC',
              rich: {
                color0: {
                  color: '#9744E1',
                },
                color1: {
                  color: '#00FFB8',
                },
                color2: {
                  color: '#00A2FF',
                },
                color3: {
                  color: '#FFDC60',
                },
                color4: {
                  color: '#00DCFB',
                }
              }
            },
            // data: [
            //   {value: 1048, name: '收费站'},
            //   {value: 735, name: '隧道'},
            //   {value: 580, name: '外场'},
            //   {value: 484, name: '服务区'},
            //   {value: 300, name: '桥梁'}
            // ],
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal:{
                color: (params) => {
                  //自定义颜色
                  let colorList = [ '#9744E1', '#00FFB8', '#00A2FF', '#FFDC60', '#00DCFB' ]
                  return colorList[params.dataIndex]
                }
              }
            }
          }
        ]
      }
    }
  },
  methods:{
    refreshData (eventData) {
      this.eventData = eventData
      let chartData = []
      chartData.push({name: '收费站', value: eventData.location.toll === 0 ? '-' : eventData.location.toll})
      chartData.push({name: '隧道', value: eventData.location.tunnel === 0 ? '-' : eventData.location.tunnel})
      chartData.push({name: '外场', value: eventData.location.outfield === 0 ? '-' : eventData.location.outfield})
      chartData.push({name: '服务区', value: eventData.location.serviceArea === 0 ? '-' : eventData.location.serviceArea})
      chartData.push({name: '桥梁', value: eventData.location.bridge === 0 ? '-' : eventData.location.bridge})
      this.polar.series[0].data = chartData
      chart.clear()
      chart.setOption(this.polar)
    },
    handleEventOver (index) {
      chart.dispatchAction({
        type: 'highlight',
        dataIndex: index
      })
    },
    handleEventLeave (index) {
      chart.dispatchAction({
        type: 'downplay',
        dataIndex: index
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      chart = echarts.init(document.getElementById('location-chart-container'))
    })
  }
}
</script>

<style lang="less" scoped>

  @import './analyse.less';

  .panel-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #0088FF;
    margin-left: 16px;
    padding-top: 16px;
  }

  .location-item:nth-child(1) {
    border-left: 1px solid #9744E1;
    .location-item-number-total{
      color: #9744E1;
      text-shadow: 0px 0px 6px #DD00FF;
    }
  }
  .location-item:nth-child(2) {
    border-left: 1px solid #00FFB8;
    .location-item-number-total{
      color: #00FFB8;
      text-shadow: 0px 0px 6px #00FFB9;
    }
  }
  .location-item:nth-child(3) {
    border-left: 1px solid #00A2FF;
    .location-item-number-total{
      color: #00CBFF;
      text-shadow: 0px 0px 6px #1100FF;
    }
  }
  .location-item:nth-child(4) {
    border-left: 1px solid #FFDC60;
    .location-item-number-total{
      color: #FFDC60;
      text-shadow: 0px 0px 6px #FFAA00;
    }
  }
  .location-item:nth-child(5) {
    border-left: 1px solid #00DCFB;
    .location-item-number-total{
      color: #00DCFB;
      text-shadow: 0px 0px 6px #00A2FF;
    }
  }

  .location-item {
    width: 180px;
    height: 36px;
    margin-top: 20px;
    margin-left: 16px;
    /*float: left;*/
    border: 1px solid rgba(0, 136, 255, 0.3);
    border-radius: 0px 8px 8px 0px ;

    .location-item-name {
      float: left;
      padding-left: 10px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 36px;
      color: #FFFFFF;
      opacity: 0.65;
    }

    .location-item-number {
      /*padding-top: 5px;*/
      line-height: 36px;

      .location-item-number-total {
        /*float: right;*/
        padding-right: 7px;
        text-align: right;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
      }

      .location-item-number-ring {
        /*float: left;*/
        position: absolute;
        margin-top: -34px;
        left: 80px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
      }

    }
  }

  .triangle-arrow {
    border-width: 4px;
  }

  .chart-wrapper {
    position: absolute;
    top: 70px;
    right: 0px;
  }

</style>
