<!--智能分析主页-->
<template>
  <div :style="{height:bodyHeight+'px'}" class="analyse-wrapper">

    <div class="analyse-title">
      <img src="../../assets/images/analyse/analyse-title.gif">
    </div>

    <total-event class="total-event" ref="totalEvent"></total-event>
    <today-video-event class="today-video-event" ref="todayVideoEvent"></today-video-event>
    <road-ranking class="road-ranking" ref="roadRanking"></road-ranking>
    <department-ranking class="department-ranking" ref="departmentRanking"></department-ranking>
    <alert-ranking class="alert-ranking" ref="alertRanking"></alert-ranking>
    <location-analyse class="location-analyse" ref="locationAnalyse"></location-analyse>
    <type-analyse class="type-analyse" :eventData="eventData"></type-analyse>
    <event-trend class="event-trend" v-if="trendData.length > 0" :trendData="trendData"></event-trend>
    <realtime-event-list class="realtime-event-list" :realtimeEventList="realtimeEventList"></realtime-event-list>

  </div>
</template>

<script>

  import { deepClone } from '../../utils/util'
  import { getExistNavigatorBodyHeignt } from '../../core/bodyHeigntUtil'
  import Panel from '../../components/common/Panel'
  import TotalEvent from "../../components/analyse/TotalEvent"
  import TodayVideoEvent from "../../components/analyse/TodayVideoEvent"
  import AlertRanking from "../../components/analyse/AlertRanking"
  import DepartmentRanking from "../../components/analyse/DepartmentRanking"
  import RoadRanking from "../../components/analyse/RoadRanking"
  import LocationAnalyse from "../../components/analyse/LocationAnalyse"
  import EventTrend from "../../components/analyse/EventTrend"
  import RealtimeEventList from "../../components/analyse/RealtimeEventList"
  import TypeAnalyse from "../../components/analyse/TypeAnalyse"
  import { getAnalyseData, getAnalyseEventData, getAnalyseEventCountsFromDate, getEventTotalCount } from "../../api/cameraAnalyse"

  let timeout = null

export default {
  name: "AnalyseMain",
  components: {
    TypeAnalyse,
    RealtimeEventList,
    EventTrend,
    LocationAnalyse, RoadRanking, DepartmentRanking, AlertRanking, TodayVideoEvent, TotalEvent, Panel },
  data(){
    return{
      bodyHeight:"",
      eventData: null, // 事件数据
      realtimeEventList: [], // 实时事件
      trendData: [] // 事件数量趋势数据
    }
  },
  methods:{
    getAnalyseEventData () { // 查询首页数据
      let param = {
        // startTime: '2021-06-07 00:00:00',
        // endTime: '2021-06-07 14:00:00',
        startTime: this.$moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), // 默认查当天0点开始
        endTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }
      getAnalyseEventData(param).then(res => {
        this.eventData = res.data
        this.$refs.todayVideoEvent.refreshData(deepClone(this.eventData))
        this.$refs.locationAnalyse.refreshData(deepClone(this.eventData))
        this.$refs.roadRanking.refreshData(deepClone(this.eventData))
        this.$refs.departmentRanking.refreshData(deepClone(this.eventData))
        this.$refs.alertRanking.refreshData(deepClone(this.eventData))
      })
    },
    getAnalyseData () { // 查询实时事件列表
      let param = {
        department: '',
        alertType: '',
        startTime: this.$moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        deviceName: '',
        fromCompany: '',
        page: 1,
        'page-size': 20
      }
      // 查询事件实时数据
      getAnalyseData(param).then(res => {
        this.realtimeEventList = res.data.data
      })
    },
    getAnalyseEventTrend () { // 查询事件数量趋势
      let param = {days: 7}
      // 查询事件实时数据
      getAnalyseEventCountsFromDate(param).then(res => {
        this.trendData = res.data
      })
    },
    getEventTotalCount () {
      getEventTotalCount().then(res => {
        this.$refs.totalEvent.refreshData(res.data)
      })
    },
  },
  beforeMount:function () {
    this.bodyHeight = getExistNavigatorBodyHeignt();
  },
  mounted:function () {
    let that = this;
    that.$nextTick(() => {

      this.getAnalyseEventData()
      this.getAnalyseData()
      this.getAnalyseEventTrend()
      this.getEventTotalCount()


      /*
      setInterval(() => {
        // 事件每10s刷新一次
        this.getAnalyseEventData()
        this.getEventTotalCount()
      }, 60000)
      setInterval(() => {
        // 事件每10s刷新一次
        this.getAnalyseData()
      }, 60000)
      */

      // 使用setTimeout实现
      clearTimeout(timeout)
      timeout = null
      const timeoutFn = () => {
        timeout = setTimeout(() => {
          this.getAnalyseEventData()
          this.getEventTotalCount()
          this.getAnalyseData()
          timeoutFn()
        }, 180000) // 3分钟刷新一次
      }
      timeoutFn()

      window.onresize = () => {
        return (() => {
          that.bodyHeight = getExistNavigatorBodyHeignt();
        })()
      }
    })
  },
  beforeDestroy() {
    clearTimeout(timeout)
    timeout = null
  }

}
</script>

<style lang="less" scoped>

  .analyse-title {
    margin: 0 auto;
    margin-top: 11px;
    width: 800px;
  }

  .analyse-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #080B45;
    overflow-y: auto;

    .total-event {
      position: absolute;
      left: 24px;
      top: 11px;
      width: 528px;
      height: 60px;
    }

    .today-video-event {
      position: absolute;
      left: 24px;
      top: 79px;
      width: 1336px;
      height: 536px;
    }

    .alert-ranking {
      position: absolute;
      left: 760px;
      top: 623px;
      width: 400px;
      height: 364px;
    }
    .department-ranking {
      position: absolute;
      right: 24px;
      top: 214px;
      width: 340px;
      height: 194px;
    }
    .road-ranking {
      position: absolute;
      right: 24px;
      top: 12px;
      width: 340px;
      height: 194px;
    }
    .location-analyse {
      position: absolute;
      left: 24px;
      top: 623px;
      width: 728px;
      height: 364px;
    }
    .type-analyse {
      position: absolute;
      right: 372px;
      top: 12px;
      width: 180px;
      height: 396px;
      text-align: center;
    }
    .event-trend {
      position: absolute;
      right: 24px;
      top: 416px;
      width: 528px;
      height: 200px;
    }
    .realtime-event-list {
      position: absolute;
      right: 24px;
      top: 623px;
      width: 728px;
      height: 364px;
    }
  }

</style>
