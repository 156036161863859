<!--实时事件列表-->
<template>
  <div>
    <panel>

      <div class="panel-title">
        实时事件展示
      </div>

      <table class="event-table" style="padding-top: 8px;">
        <thead>
        <tr>
          <th style="width: 144px;">事件种类</th>
          <th style="width: 80px;">发生高速</th>
          <th style="width: 310px;">发生路段</th>
          <th>发生时间</th>
        </tr>
        </thead>
      </table>

      <div class="event-table-body-wrapper">

        <empty v-if="realtimeEventList.length === 0" :description="'暂无数据'" style="height: 240px;"/>

        <table v-else class="event-table" style="overflow-y: scroll;">
          <tbody>

          <tr v-for="item of realtimeEventList" :key="item.id">
            <td style="width: 144px;" :title="VIDEO_EVENT_ENUM.alertType[item.alertType]">
<!--              <img src="../../assets/images/analyse/icon_events_unusual.png" style="margin-right: 4px;">{{VIDEO_EVENT_ENUM.alertType[item.alertType]}}-->
              <img :src="VIDEO_EVENT_ENUM.img[item.alertType]" style="margin-right: 4px; height: 20px; width: 20px;">{{VIDEO_EVENT_ENUM.alertType[item.alertType]}}
            </td>
            <td style="width: 80px;">
              <div class="road-sign">{{item.road}}</div>
            </td>
            <td style="width: 310px;" :title="item.deviceName">
              {{item.deviceName}}
            </td>
            <td>
              {{$moment(item.alertStartTime).format('YYYY-MM-DD HH:mm:ss')}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </panel>
  </div>
</template>

<script>

  import {VIDEO_EVENT_ENUM} from '../../core/enums'
  import Panel from '../../components/common/Panel'
  import Empty from '../../components/common/Empty'

export default {
  name: "RealtimeEventList",
  components: { Panel, Empty },
  props: {
    realtimeEventList: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      VIDEO_EVENT_ENUM
    }
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>

  .panel-title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #0088FF;
    margin-left: 16px;
    padding-top: 16px;
  }

  .event-table {
    padding-left: 16px;
    padding-right: 16px;

    width: 100%;
    border-collapse: separate;
    /*border-collapse: collapse;*/
    border-spacing: 0px 2px;
    table-layout: fixed;

    thead {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.65);
      background-color: #082B76;
      height: 36px;

      th {
        /*text-align: left;*/
        height: 36px;
        padding-left: 12px;
      }
    }

    tbody {
      font-size:12px;
      font-family:Microsoft YaHei;
      font-weight:400;
      line-height:14px;
      color:rgba(255,255,255,1);

      tr {
        &:hover {
          filter: brightness(1.2);
        }
      }

      td {
        text-align: left;
        height: 36px;
        padding-left: 12px;

        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0088FF;
      }
    }
  }

  .event-table-body-wrapper {
    height: 260px;
    overflow-y: auto;

    td {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .road-sign {
    text-align: center;
    width: 42px;
    height: 18px;
    line-height: 16px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    background: #00910A;
    border: 1px solid #FFFFFF;
    border-radius: 6px;
  }

</style>
