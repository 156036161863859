<!--线路事件排名-->
<template>
  <div>
    <panel>

      <div class="panel-title">
        线路事件排名
      </div>
      <div class="chart-wrapper">
        <div id="road-chart-container" style="height: 154px; width: 340px;"></div>
      </div>

    </panel>
  </div>
</template>

<script>

  import Panel from '../../components/common/Panel'
  import echarts from 'echarts/lib/echarts'
  import ECharts from 'vue-echarts'
  import 'echarts/index'
  import { HIGHWAY_ENUM } from '../../core/enums.js'

  let chart

export default {
  name: "RoadRanking",
  components: {
    Panel,
    'v-chart': ECharts
  },
  data(){
    return{
      HIGHWAY_ENUM,
      polar: {
        grid: {
          left: 110,
          top: 8,
          width: 170,
          height: 132,
        },
        xAxis: {
          type: 'value',
          show: false,
          position: 'top'
        },
        yAxis: {
          type: 'category',
          // data: ['G30青兰高速', 'G30青兰高速', 'G5高速', 'G30青兰高速', 'G30青兰高速'],
          data: [],
          axisTick: {
            show: false
          },
          axisLine: {       //y轴
            show: false
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.65)',
            fontSize: 14,
            fontWeight: 400,
            fontFamily: 'PingFang SC',
            formatter: function (value) {   // 防止名称过长超出边界
              let res = value
              if (res.length > 9) {
                res = res.substring(0, 8) + '...'
              }
              return res
            }
          }
        },
        series: [{
          // data: [200, 150, 120, 80, 70],
          data: [],
          type: 'bar',
          barWidth: 8,
          label: {
            show: true,
            color: '#0088FF',
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: 'PingFang SC',
            position: 'right',
          },
          itemStyle:{
            color: {
              colorStops: [{
                offset: 0,   //颜色的开始位置
                color: '#00CBFF' // 0% 处的颜色
              },{
                offset: 1,    //颜色的结束位置
                color: '#0088FF' // 100% 处的颜色
              }]
            },
            barBorderRadius: [4] //设置圆角
          }
        }]
      }
    }
  },
  methods:{
    refreshData (eventData) {

      let roadNames = Object.keys(eventData.road)
      let roadCounts = Object.values(eventData.road)

      if (roadNames.length < 5) { // 手动补齐五条数据
        for(let key in HIGHWAY_ENUM.properties){
          if (roadNames.indexOf(HIGHWAY_ENUM.properties[key].label) === -1) {
            roadNames.unshift(HIGHWAY_ENUM.properties[key].label)
            roadCounts.unshift(0)
          }
          if (roadNames.length === 5) {
            break
          }
        }
      }
      this.polar.yAxis.data = roadNames
      this.polar.series[0].data = roadCounts
      chart.clear()
      chart.setOption(this.polar)
    }
  },
  mounted () {
    this.$nextTick(() => {
      chart = echarts.init(document.getElementById('road-chart-container'))
    })
  }
}
</script>

<style lang="less" scoped>

  .panel-title {
    /*height: 54px;*/
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    /*line-height: 54px;*/
    color: #0088FF;
    margin-left: 16px;
    padding-top: 16px;
  }


  .chart-wrapper {
    position: relative;
    width: 340px;
    height: 194px;
  }

</style>
