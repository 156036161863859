<!--今日视频事件-->
<template>
    <div>
        <panel>

            <div>
                <div class="event-total">
                    <div class="event-total-title">今日视频分析事件</div>
                    <div class="event-total-count animated" v-if="eventData !== null" :class="{flipInX: flushFlag}">{{eventData.totalEvent}}</div>
                    <div class="event-total-ring" v-if="eventData !== null" :class="{'ring-count-up': eventData.totalEventRingRatio >= 0, 'ring-count-down': eventData.totalEventRingRatio < 0}">
                        <span class="triangle-arrow"></span>
                        <span style="margin-left: 4px;">{{Math.abs(eventData.totalEventRingRatio)}}</span>
                    </div>
                </div>
            </div>

            <div class="event-chart">
                <div id="type-chart-container" style="height: 200px; width: 200px;"></div>
            </div>

            <div class="sidebar-pic" v-if="eventData !== null">
                <img src="../../assets/images/analyse/dynamic-sidebar.gif" style="width: 76px;" :style="{height: 33 * eventData.alert.length + 'px'}">
            </div>

            <div class="event-list" v-if="eventData !== null">
                <div class="event-list-item" v-for="(item,index) in eventData.alert" :key="index" @click="handleEventItemClick(item.alertType, index)"  @mouseover="handleEventOver(index)"
                     @mouseleave="handleEventLeave(index)">
                    <!--背景图-->
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                        <!--36高度-->
<!--                        <g :fill="index === currentIndex ? colorList[index] : 'none'"-->
<!--                           :opacity="index === currentIndex ? 0.35 : 'none'" transform="translate(3034 16101)">-->
<!--                            <path d="M-2828-16065.368v.367h-206v-36h206v0l.242.437.044,0,.889,1.676L-2818-16083l-9.279,16.7-.435.932h-.083l-.2.367Z"/>-->
<!--                            <path fill="rgba(255,255,255,0.45)"-->
<!--                                  d="M -2828.5869140625 -16066.0009765625 L -2828.382568359375 -16066.3681640625 L -2828.3486328125 -16066.3681640625 L -2828.182861328125 -16066.72265625 L -2828.16796875 -16066.7548828125 L -2828.15087890625 -16066.78515625 L -2819.14208984375 -16082.998046875 L -2827.697509765625 -16098.3984375 L -2827.7021484375 -16098.40625 L -2827.70654296875 -16098.4150390625 L -2828.341064453125 -16099.611328125 L -2828.373046875 -16099.6142578125 L -2828.58740234375 -16100 L -3033 -16100 L -3033 -16066.0009765625 L -2828.5869140625 -16066.0009765625 M -2827.9990234375 -16065.0009765625 L -2827.9990234375 -16065.3681640625 L -2827.9990234375 -16065.0009765625 L -3034 -16065.0009765625 L -3034 -16101 L -2827.9990234375 -16101 L -2827.9990234375 -16100.5849609375 L -2827.9990234375 -16101 L -2827.756591796875 -16100.5634765625 L -2827.712158203125 -16100.5595703125 L -2826.8232421875 -16098.8837890625 L -2817.998046875 -16082.998046875 L -2827.27685546875 -16066.2998046875 L -2827.712158203125 -16065.3681640625 L -2827.794677734375 -16065.3681640625 L -2827.9990234375 -16065.0009765625 Z"/>-->
<!--                        </g>-->
<!--                        <rect :fill="colorList[index]" width="4" height="36"/>-->
<!--                        <path :fill="index === currentIndex ? colorList[index] : 'none'"-->
<!--                              d="M-4593-16924v-.008L-4583-16942l-9.994-17.992v-.008h6l10,18-10,18Zm-10,0,10-18-10-18h5l10,18-10,18ZM-4805-16960l10,18-10,18Z"-->
<!--                              transform="translate(4809 16960)"/>-->

                        <!--33高度-->
                        <g :fill="index === currentIndex ? colorList[index] : 'none'"
                           :opacity="index === currentIndex ? 0.35 : 'none'" transform="translate(3034 16101)">
                            <path d="M-2828-16068.755v.756h-206v-33h206v0l10,16.5-10,16.5Z"/>
                            <path fill="rgba(255,255,255,0.45)"
                                  d="M -2828.562255859375 -16068.9990234375 L -2819.16748046875 -16084.501953125 L -2828.562255859375 -16100 L -3033 -16100 L -3033 -16068.9990234375 L -2828.562255859375 -16068.9990234375 M -2827.9990234375 -16067.9990234375 L -2827.9990234375 -16068.7548828125 L -2827.9990234375 -16067.9990234375 L -3034 -16067.9990234375 L -3034 -16101 L -2827.9990234375 -16101 L -2827.9990234375 -16100.2861328125 L -2827.9990234375 -16101 L -2817.998046875 -16084.501953125 L -2827.9990234375 -16067.9990234375 Z"/>
                        </g>
                        <rect :fill="colorList[index]" width="4" height="33"/>
                        <path :fill="index === currentIndex ? colorList[index] : 'none'"
                              d="M-4593-16927v-.01l9.994-16.488-9.994-16.494v-.008h6l10,16.5-10,16.5Zm-10,0,10-16.5-10-16.5h5l10,16.5-10,16.5ZM-4805-16960l10,16.5-10,16.5Z"
                              transform="translate(5402 16960)"/>
                    </svg>
                    <!--文字内容-->
                    <div class="event-list-content" :style="{opacity: index === currentIndex ? 1 : 0.65}">
                        <span class="event-list-item-title" :style="{fontSize: item.alertType === 'K' ? '11px' : '14px'}">{{item.alertName}}</span>
                        <span class="event-list-item-ring" :class="{'ring-count-up': item.ringRatio >= 0, 'ring-count-down': item.ringRatio < 0}">
                          <span class="triangle-arrow"></span>
                          <span style="margin-left: 2px;">{{Math.abs(item.ringRatio)}}</span>
                        </span>
                        <span class="event-list-item-count">{{item.count}}</span>
                    </div>
                </div>
            </div>

            <heat-map ref="heatMap"></heat-map>
        </panel>
    </div>
</template>

<script>

    import { getHeatMapData } from "../../api/cameraAnalyse"
    import {VIDEO_EVENT_ENUM} from '../../core/enums'
    import Panel from '../../components/common/Panel'
    import HeatMap from "./HeatMap"
    import echarts from 'echarts/lib/echarts'
    import ECharts from 'vue-echarts'
    import 'echarts/index'

    // 颜色列表
    const colorList = [
        '#00A2FF', '#A8AB79', '#00FFB8', '#FFDC60', '#FF636D', '#00DCFB', '#00C396', '#FF8336',
        '#9744E1', '#EE74FC', '#0008FF', '#1FD255', '#F2FF3B', '#BF3B43', '#88FFF7'
    ]

    // 饼图文字颜色
    let richStyle = {}
    colorList.forEach((item, index) => {
        richStyle['style' + index] = {
            color: item,
            fontSize: 24
        }
    })

    let chart

    /*
    事件展示顺序:
      交通拥堵 事故事件 火灾 烟雾 逆行 占急 避险车道  抛洒物 停驶  慢行 摩托  行人  施工 能见度  两客一危
      A O J F H M I C B E L D G K N
     */

    export default {
        name: "TodayVideoEvent",
        components: {HeatMap, Panel, 'v-chart': ECharts},
        data() {
            return {
                VIDEO_EVENT_ENUM,
                flushFlag: true, // 数据刷新标识,用于控制刷新效果
                eventData: null,
                polar: {
                    title: {
                        show: true,
                        text: '事件类型占比',
                        left: 'center',
                        top: '42%',
                        textStyle: {
                            color: 'rgba(255, 255, 255, 0.85)',
                            fontSize: 20,
                            fontWeight: 800,
                        }
                    },
                    color: colorList,
                    series: [
                        {
                            name: '访问来源',
                            type: 'pie',
                            radius: ['70%', '90%'],
                            // avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    color: 'rgba(255, 255, 255, 0.85)',
                                    fontSize: 20,
                                    fontWeight: 800,
                                    fontFamily: 'PingFang SC',
                                    formatter: (params) => {
                                        return `{style${params.dataIndex}|${params.name}}` + '\n' + `{styleValue|${params.value}}` + '\n' + `{stylePercent|（${params.percent}%）}`
                                    },
                                    rich: {
                                        ...richStyle,
                                        styleValue: {
                                            color: 'rgba(255, 255, 255, 0.85)',
                                            fontSize: 24,
                                        },
                                        stylePercent: {
                                            color: 'rgba(255, 255, 255, 0.85)',
                                            fontSize: 18,
                                        }
                                    }
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            // data: [
                            //   {value: 1048, name: '交通拥堵'},
                            //   {value: 735, name: '车辆停驶'},
                            //   {value: 580, name: '行人'},
                            //   {value: 484, name: '车辆慢行'},
                            //   {value: 300, name: '烟雾事件'}
                            // ]
                            data: []
                        }
                    ]
                },
                currentIndex: 0,
                colorList: colorList
            }
        },
        methods: {
            refreshData (eventData) {
                this.eventData = eventData
                this.dataSort()
                this.refreshChartData()
                this.setHeatmapData(eventData.alert[0].alertType, 0)

                // 刷新特效
                this.flushFlag = false
                setTimeout(() => {
                    this.flushFlag = true
                }, 100)
            },
            setHeatmapData (alertType, index) {
                let param = {
                    alertType: alertType,
                    startTime: this.$moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    endTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                }
                getHeatMapData(param).then(res => {
                    this.$refs.heatMap.setHeatmapData(res.data)
                    this.currentIndex = index
                })
            },
            handleEventItemClick (alertType, index) {
              this.setHeatmapData(alertType, index) // 设置热力图数据
            },
            handleEventOver(index) {
                chart.dispatchAction({
                    type: 'highlight',
                    dataIndex: index
                })
                this.hideChartTitle()
            },
            handleEventLeave(index) {
                chart.dispatchAction({
                    type: 'downplay',
                    dataIndex: index
                })
                this.showChartTitle()
            },
            showChartTitle() {
                this.polar.title.show = true
                chart.setOption(this.polar)
            },
            hideChartTitle() {
                this.polar.title.show = false
                chart.setOption(this.polar)
            },
            refreshChartData () { // 刷新chart数据
                let chartData = []
                this.eventData.alert.forEach(item => {
                    chartData.push({name: item.alertName, value: item.count})
                })
                this.polar.series[0].data = chartData
                chart.clear()
                chart.setOption(this.polar)
            },
            dataSort() { // 按照规定顺序对数据进行排序
                let sortRule = ['A', 'O', 'J', 'F', 'H', 'M', 'I', 'C', 'B', 'E', 'L', 'D', 'G', 'K', 'N']
                let sortData = []
                sortRule.forEach(rule => {
                    let temp = this.eventData.alert.find(v => v.alertType === rule)
                    if (temp !== undefined) {
                        sortData.push(temp)
                    }
                })
                this.eventData.alert = sortData
            }
        },
        mounted() {
            this.$nextTick(() => {
                chart = echarts.init(document.getElementById('type-chart-container'))
                chart.on('mouseover', () => {
                    this.hideChartTitle()
                })
                chart.on('mouseout', () => {
                    this.showChartTitle()
                })

                // 事件选中轮播
                setInterval(() => {
                    if (this.eventData !== null) {
                        if (this.currentIndex === this.eventData.alert.length - 1) {
                            this.currentIndex = 0
                        } else {
                            this.currentIndex++
                        }
                        this.setHeatmapData(this.eventData.alert[this.currentIndex].alertType, this.currentIndex)
                    }
                }, 10000)

            })
        }
    }
</script>

<style lang="less" scoped>

    @import './analyse.less';

    .event-total {
        width: 200px;
        height: 160px;
        position: absolute;
        top: 76px;
        left: 24px;
        text-align: center;
        background-image: url("../../assets/images/analyse/bg-today-event-analyse.png");
        padding-top: 28px;

        .event-total-title {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #0088FF;
        }

        .event-total-count {
            font-size: 42px;
            font-family: lcd;
            font-weight: 400;
            line-height: 51px;
            color: #00CBFF;
            text-shadow: 0px 0px 6px #1100FF;
        }

        .event-total-ring {
            font-size: 24px;
            font-family: lcd;
            font-weight: 400;
            line-height: 29px;
        }

        .ring-count-up {
            .triangle-arrow {
                top: -14px;
            }
        }

        .ring-count-down {
            .triangle-arrow {
                top: 16px;
            }
        }
    }

    .event-chart {
        height: 200px;
        position: absolute;
        top: 260px;
        left: 24px;
    }

    .sidebar-pic {
        position: absolute;
        top: 18px;
        left: 244px;
        /*overflow: hidden;*/
    }

    .event-list {
        position: absolute;
        top: 18px;
        left: 324px;
        /*z-index: 100;*/

        .event-list-item {
            width: 232px;
            height: 33px;
            position: relative;
            cursor: pointer;

            &:hover {
                /*filter: brightness(1.4);*/

                .event-list-content {
                    opacity: 1 !important;
                }
            }

            .event-list-content {
                position: absolute;
                top: 0px;
                left: 9px;
                line-height: 33px;
                width: 197px;
            }

            .event-list-item-title {
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 20px;
                color: #FFFFFF;
            }

            .event-list-item-ring {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 33px;
                position: absolute;
                left: 109px;

                .triangle-arrow {
                    border-width: 4px;
                }
            }

            .ring-count-up {
                .triangle-arrow {
                    top: -9px;
                }
            }

            .ring-count-down {
                .triangle-arrow {
                    top: 11px;
                }
            }

            .event-list-item-count {
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 20px;
                color: #FFFFFF;
                float: right;
                line-height: 33px;
            }
        }
    }

</style>
