<!--行为分析排名-->
<template>
  <div>
    <panel>
      <div class="panel-title">
        行为分析排名
      </div>
      <div class="chart-wrapper">
<!--        <v-chart style="height: 302px; width: 368px;"  :options="polar"></v-chart>-->
        <div id="alert-chart-container" style="height: 302px; width: 100%;"></div>
      </div>

    </panel>
  </div>
</template>

<script>

  import Panel from '../../components/common/Panel'
  import echarts from 'echarts/lib/echarts'
  import ECharts from 'vue-echarts'
  import 'echarts/index'

  let chart

  export default {
    name: "AlertRanking",
    components: {
      Panel,
      'v-chart': ECharts
    },
    props: {
      eventData: {
        type: Object,
        default: () => {}
      }
    },
    data(){
      return{
        polar: {
          grid: {
            left: 148,
            top: 8,
            width: 210,
            height: 302,
          },
          xAxis: {
            type: 'value',
            show: false,
            position: 'top'
          },
          yAxis: {
            type: 'category',
            // data: ['占用应急车道', '占用应急车道', '占用应急车道', '占用应急车道', '占用应急车道', '占用应急车道', '占用应急车道', '占用应急车道', '占用应急车道', '占用应急车道'],
            data: [],
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.65)',
              fontSize: 14,
              fontWeight: 400,
              fontFamily: 'PingFang SC',
            }
          },
          series: [{
            // data: [800, 700, 650, 500, 400, 300, 200, 150, 80, 70],
            data: [],
            type: 'bar',
            barWidth: 8,
            label: {
              show: true,
              color: '#0088FF',
              fontSize: 14,
              fontWeight: 'bold',
              fontFamily: 'PingFang SC',
              position: 'right',
            },
            itemStyle:{
              color: {
                colorStops: [{
                  offset: 0,   //颜色的开始位置
                  color: '#00CBFF' // 0% 处的颜色
                },{
                  offset: 1,    //颜色的结束位置
                  color: '#0088FF' // 100% 处的颜色
                }]
              },
              barBorderRadius: [4] //设置圆角
            }
          }]
        }
      }
    },
    methods:{
      refreshData (eventData) {
        eventData.alert.sort((a, b) => a.count - b.count)
        let yAxisData = []
        let seriesData = []
        eventData.alert.slice(-10).forEach(item => {
          yAxisData.push(item.alertName)
          seriesData.push(item.count)
        })

        this.polar.yAxis.data = yAxisData
        this.polar.series[0].data = seriesData

        chart.clear()
        chart.setOption(this.polar)
      }
    },
    mounted () {
      this.$nextTick(() => {
        chart = echarts.init(document.getElementById('alert-chart-container'))
      })
    }
  }
</script>

<style lang="less" scoped>

  .panel-title {
    /*height: 54px;*/
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    /*line-height: 54px;*/
    color: #0088FF;
    margin-left: 16px;
    padding-top: 16px;
  }


  .chart-wrapper {
    position: relative;
    width: 340px;
    width: 100%;
    height: 194px;
  }

</style>
