<!--累计视频分析事件-->
<template>
  <div>
    <panel>
      <div style="line-height: 60px; margin-left: 24px;">
        <span class="event-total-title">累计视频分析事件</span>
        <div class="event-total-number animated" :class="{flipInX: flag}">{{totalCount.toLocaleString()}}</div>
      </div>
    </panel>
  </div>
</template>

<script>

  import Panel from '../../components/common/Panel'

export default {
  name: "TotalEvent",
  components: { Panel },
  data(){
    return{
      flag: true,
      totalCount: 0
    }
  },
  methods:{
    refreshData (totalCount) {
      this.totalCount = totalCount
      this.refresh()
    },
    refresh () { // 数字刷新特效
      this.flag = false
      setTimeout(() => {
        this.flag = true
      }, 100)
    }
  }
}
</script>

<style lang="less" scoped>

  .event-total-title {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #0088FF;
  }

  .event-total-number {
    font-family: lcd;
    font-size: 32px;
    color: #00CBFF;
    float: right;
    margin-right: 16px;
  }

</style>
