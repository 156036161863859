<!--事件趋势-->
<template>
  <div>
    <panel>

      <div class="panel-title">
        事件数量趋势（7日）
      </div>
      <div class="chart-wrapper">
        <v-chart style="height: 168px; width: 528px;"  :options="polar"></v-chart>
      </div>

    </panel>
  </div>
</template>

<script>

  import Panel from '../../components/common/Panel'
  import ECharts from 'vue-echarts'
  import 'echarts/index'

  export default {
    name: "EventTrend",
    components: {
      Panel,
      'v-chart': ECharts
    },
    props: {
      trendData: {
        type: Array,
        default: () => []
      }
    },
    data(){
      return{
        polar: {
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: 50,
            top: 20,
            width: 450,
            height: 100,
          },
          xAxis: {
            type: 'category',
            // data: ['5月27日', '5月28日', '5月29日', '5月30日', '5月31日', '6月1日', '6月2日'],
            data: this.trendData.map(v => this.$moment(v.date).format('M月D日')),
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#0088FF',
              fontSize: 12,
              fontWeight: 400,
              fontFamily: 'PingFang SC',
            }
          },
          yAxis: {
            type: 'value',
            name: '次',
            boundaryGap: ['0%', '10%'],
            nameTextStyle: {
              padding: [0, 30, -20, 0],
              color: '#0088FF',
              fontSize: 12,
              fontWeight: 400,
              fontFamily: 'PingFang SC',
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 136, 255, 0.16)'
              }
            },
            axisLabel: {
              showMaxLabel: false,
              color: '#0088FF',
              fontSize: 12,
              fontWeight: 400,
              fontFamily: 'PingFang SC',
            }
          },
          series: [{
            // data: [150, 230, 224, 218, 135, 147, 260],
            data: this.trendData.map(v => v.count),
            type: 'line',
            symbolSize: 6,   //拐点圆的大小
            symbol: 'circle', //拐点样式
            lineStyle: {
              color: '#00CBFF',
              width: 2
            },
            itemStyle: {
              color: '#00CBFF',
            }
          }]
        }
      }
    },
    methods:{

    },
    mounted () {
      this.$nextTick(() => {
      })
    }
  }
</script>

<style lang="less" scoped>

  .panel-title {
    /*height: 54px;*/
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 800;
    /*line-height: 54px;*/
    color: #0088FF;
    margin-left: 16px;
    padding-top: 16px;
  }


  .chart-wrapper {
    position: relative;
    width: 340px;
    height: 194px;
  }

</style>
